import axios from 'axios';
import getConfig from 'next/config';
import getSubDomain from './getSubDomain';

const { publicRuntimeConfig } = getConfig();

const validateCart = async (items = [], token, locale = 'en') => {
  let authentication_header = {};
  if (token) authentication_header = { headers: { Authorization: 'bearer ' + token } };
  const subdomain = getSubDomain();
  let endpoint_validation = publicRuntimeConfig.API_PATH + '/user/me/cart/validation';
  if (subdomain === 'business') {
    endpoint_validation = publicRuntimeConfig.API_PATH + '/business/user/me/cart/validation';
  }
  const response = await axios.post(
    endpoint_validation,
    {
      items: JSON.stringify(items),
      locale: locale,
    },
    authentication_header,
  );
  return response;
};

export default validateCart;
