import { CITIES } from '../types';

const setCities = (cityObject) => {
  return (dispatch) => {
    dispatch({type: CITIES, payload: cityObject});
  };
};

export default {
    setCities,
};
