import { SWEETALERT, SUBMITTING, LOADING, MODALMEMBER } from '../types';

const onLoginError = (err, dispatch) => {
  dispatch({ type: LOADING, payload: false });
  dispatch({ type: SUBMITTING, payload: false });
  if (err?.response?.data?.detail) {
    const message = err.response.data.detail;
    if (message === 'not exist email.' || message === 'email is not registered.') {
      dispatch({
        type: SWEETALERT,
        payload: {
          title: 'Oops! This email is not registered. Please try again.',
          show: true,
        },
      });
    } else if (message === 'invalid password.' || message === 'Invalid password.') {
      const title = (
        <>
          Oops! Incorrect password. <br />
          Please try again.
        </>
      );
      dispatch({
        type: SWEETALERT,
        payload: {
          title,
          show: true,
        },
      });
    } else if (message === 'Account has not been approved yet.') {
      dispatch({
        type: SWEETALERT,
        payload: {
          title: 'Oops! Your account has not been approved yet.',
          show: true,
        },
      });
    } else if (message === 'Account is not approved.') {
      dispatch({
        type: SWEETALERT,
        payload: {
          title: 'Oops! Your account is not approved.',
          show: true,
        },
      });
    } else if (message === 'not exist facebook id.') {
      dispatch({
        type: SWEETALERT,
        payload: {
          title: 'Oops! This facebook account is not registered. Please register or link your facebook account.',
          show: true,
          onConfirm: () =>
            dispatch({
              type: MODALMEMBER,
              payload: {
                show: true,
                type: 'signup',
              },
            }),
        },
      });
    } else if (message === 'An unhandled exception occurred.') {
      dispatch({
        type: SWEETALERT,
        payload: {
          title: 'User login failed.\nPlease wait a moment and try again.',
          show: true,
        },
      });
    } else if (message === 'The email already exists and connected to different facebook account.') {
      dispatch({
        type: SWEETALERT,
        payload: {
          title: 'Signup failed.\nEmail already exists and is connected to different facebook account.',
          show: true,
        },
      });
    } else {
      dispatch({
        type: SWEETALERT,
        payload: 'User login failed.\nPlease wait a moment and try again.',
      });
    }
  } else {
    dispatch({
      type: SWEETALERT,
      payload: {
        title: 'User login failed.\nPlease wait a moment and try again.',
        show: true,
      },
    });
  }
  return err;
};

export default onLoginError;
