import { SETRESERVATION } from '../types';

const setReservation = (payload) => {
  return (dispatch) => {
    dispatch({type: SETRESERVATION, payload});
  };
};

export default {
  setReservation,
};
