import { SERVER } from '../types';

const setServer = payload => {
  return dispatch => {
    dispatch({ type: SERVER, payload });
  };
};

export default {
  setServer,
};
