import { RECENTLY } from '../types';

// get current currency
const changeRecently = (recentlyObject) => {
  return (dispatch) => {
    dispatch({type: RECENTLY, payload: recentlyObject});
  };
};

export default {
    changeRecently,
};
