import axios from 'axios';
import { AUTHENTICATE_COMPANY, DEAUTHENTICATE_COMPANY, USER_COMPANY, SUBMITTING, LOADING } from '../types';
import { setCookie, removeCookie, setEncryptedCookie } from '/utils/cookie';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
import Router from 'next/router';
import onLoginError from './_onLoginError';

const authenticateCompany = ({ username, password }) => {
  return async dispatch => {
    dispatch({ type: SUBMITTING, payload: true });
    dispatch({ type: LOADING, payload: true });
    const onLoginSuccess = res => {
      const { token, company } = res.data;
      dispatch({ type: SUBMITTING, payload: false });
      dispatch({ type: LOADING, payload: false });
      setCookie('token_company', token);
      setEncryptedCookie('user_company', company);
      dispatch({
        type: AUTHENTICATE_COMPANY,
        payload: token,
      });
      dispatch({
        type: USER_COMPANY,
        payload: company,
      });
      return res;
    };
    try {
      let form = new FormData();
      form.append('username', username);
      form.append('password', password);
      dispatch({ type: LOADING, payload: true });
      const response = await axios.post(`${publicRuntimeConfig.API_PATH}/company/login`, form);
      return onLoginSuccess(response);
    } catch (err) {
      return onLoginError(err, dispatch);
    }
  };
};
const reauthenticateCompany = (token, user) => {
  return dispatch => {
    dispatch({ type: AUTHENTICATE_COMPANY, payload: token });
    dispatch({ type: USER_COMPANY, payload: user });
  };
};
const logoutCompany = (dispatch, ctx) => {
  removeCookie('token_company');
  removeCookie('user_company');
  dispatch({ type: DEAUTHENTICATE_COMPANY });
  if (ctx && ctx.res) {
    ctx.res.writeHead(302, {
      Location: `/affiliatepartner`,
    });
    ctx.res.end();
  } else {
    Router.push(`/affiliatepartner`);
  }
};
const deauthenticateCompany = (token, ctx) => {
  return dispatch => {
    axios
      .post(
        `${publicRuntimeConfig.API_PATH}/company/logout`,
        {},
        {
          headers: {
            Authorization: 'bearer ' + token,
          },
        },
      )
      .then(() => {
        logoutCompany(dispatch, ctx);
      })
      .catch(() => {
        logoutCompany(dispatch, ctx);
      });
  };
};

export default {
  authenticateCompany,
  reauthenticateCompany,
  deauthenticateCompany,
};
