import { SUBDOMAIN } from '../types';

const setSubDomain = subdomain => {
  return dispatch => {
    dispatch({ type: SUBDOMAIN, payload: subdomain });
  };
};

export default {
  setSubDomain,
};
