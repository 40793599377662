import axios from 'axios';
import {
  AUTHENTICATE_PARTNER,
  DEAUTHENTICATE_PARTNER,
  USER_PARTNER,
  SUBMITTING,
  LOADING,
} from '../types';
import { setCookie, removeCookie, setEncryptedCookie } from '/utils/cookie';
import getConfig from 'next/config';
import Router from 'next/router';
import onLoginError from './_onLoginError';

const { publicRuntimeConfig } = getConfig();

const authenticatePartner = ({ email, password }) => {
  return async dispatch => {
    dispatch({ type: SUBMITTING, payload: true });
    dispatch({ type: LOADING, payload: true });
    const onLoginSuccess = res => {
      dispatch({ type: SUBMITTING, payload: false });
      dispatch({ type: LOADING, payload: false });
      setCookie('token_partner', res.data.token);
      setEncryptedCookie('user_partner', res.data.partner);
      dispatch({
        type: AUTHENTICATE_PARTNER,
        payload: res.data.token,
      });
      dispatch({
        type: USER_PARTNER,
        payload: res.data.partner,
      });
      return res;
    };
    try {
      let form = new FormData();
      form.append('email', email);
      form.append('password', password);
      dispatch({ type: LOADING, payload: true });
      const response = await axios.post(
        `${publicRuntimeConfig.API_PATH}/partner/login`,
        form
      );
      return onLoginSuccess(response);
    } catch (err) {
      return onLoginError(err, dispatch);
    }
  };
};
const reauthenticatePartner = (token, user) => {
  return dispatch => {
    dispatch({ type: AUTHENTICATE_PARTNER, payload: token });
    dispatch({ type: USER_PARTNER, payload: user });
  };
};
const logoutPartner = (dispatch, ctx) => {
  removeCookie('token_partner');
  removeCookie('user_partner');
  dispatch({ type: DEAUTHENTICATE_PARTNER });
  if (ctx && ctx.res) {
    ctx.res.writeHead(302, {
      Location: `/partner`,
    });
    ctx.res.end();
  } else {
    Router.push(`/partner`);
  }
};
const deauthenticatePartner = (token, ctx) => {
  return dispatch => {
    axios
      .post(
        `${publicRuntimeConfig.API_PATH}/partner/logout`,
        {},
        {
          headers: {
            Authorization: 'bearer ' + token,
          },
        }
      )
      .then(() => {
        logoutPartner(dispatch, ctx);
      })
      .catch(() => {
        logoutPartner(dispatch, ctx);
      });
  };
};


export default {
  reauthenticatePartner,
  authenticatePartner,
  deauthenticatePartner,
};
