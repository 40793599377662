import { Map } from 'immutable';
import validateAndGetResult from '/utils/validateAndGetResult';

const validateAndFilter = async (cart, token, locale = 'en') => {
  let cart_for_view = Map({});
  let cart_updated = Map({});
  let cart_for_view_updated = Map({});
  if (cart.size) {
    const result = await validateAndGetResult({
      cart,
      token,
      is_called_from_load: true,
      locale,
    });
    cart_for_view = result && result.cart ? result.cart : Map({});
    if (cart_for_view.size) {
      cart_for_view.entrySeq().forEach(([key, value]) => {
        if (value.get('validation') !== 0 || value.get('detail') === 'invalid participants.') {
          const new_cart_value = cart.get(key);
          cart_updated = cart_updated.set(key, new_cart_value);
          cart_for_view_updated = cart_for_view_updated.set(key, value);
        }
      });
    }
  }
  return {
    cart_for_view: cart_for_view_updated,
    cart: cart_updated,
  };
};

export default validateAndFilter;
