import { TRANSLATION } from '../types';
import { readCookie } from '/utils/cookiesTranslation';

// get current TRANSLATION
const changeTranslation = () => {
  const translation = readCookie('googtrans');
  let language_code = '';
  if (translation) language_code = translation.replace('/en/', '');
  return dispatch => {
    dispatch({ type: TRANSLATION, payload: language_code });
  };
};

export default {
  changeTranslation,
};
