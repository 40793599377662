import { Map, fromJS } from 'immutable';
import validateCart from './validateCart';
import {getCookie} from "./cookie";

const validateAndGetResult = async ({ cart = Map({}), token, source, locale = 'en' }) => {
  let cart_formatted = [];
  cart.valueSeq().forEach(cart_item_value => {
    if (cart_item_value.get('participant_option')) {
      const participants = cart_item_value.get('participant_option').map(item => {
        return item.get('qty');
      });
      const input = {
        activity_id: parseInt(cart_item_value.get('activity_id')),
        option_id: parseInt(cart_item_value.get('option_id')),
        reserve_time: cart_item_value.get('reserve_time'),
        reserve_date: cart_item_value.get('reserve_date'),
        participants: participants.toJS(),
        cart_order: cart_item_value.get('cart_order'),
        spckey: getCookie(`spckey${parseInt(cart_item_value.get('activity_id'))}`)
      };
      cart_formatted.push(input);
    }
  });
  const validation_response = await validateCart(cart_formatted, token, locale);
  let is_passed = true;
  if (validation_response.data) {
    const data = validation_response.data;
    if (data && data.results) {
      const results = Object.values(data.results);
      let valid_cart = {};
      for (const [k, v] of Object.entries(data.results)) {
        if (v.validation === 1 || v.detail === 'invalid participants.') {
          if (source === 'experience') {
            if (!v.name) v.name = v.title;
            v.reserve_date = v.reserve_date.substr(4,2)+'/'+v.reserve_date.substr(6,2)+'/'+v.reserve_date.substr(0,4);
          }
          valid_cart[k] = v;
        }
      }
      cart = fromJS(valid_cart);
      if (results && results.length) {
        if (source === 'experience') {
          is_passed = !results.some(v => {
            return v.validation === 0;
          });
        } else {
          is_passed = !results.some(v => {
            return v.validation === 0 && v.detail === 'invalid participants.';
          });
        }
      }
    }
  }
  return {
    cart,
    is_passed,
  };
};

export default validateAndGetResult;
