import { LANGUAGEINFO } from '../types';

// get current currency
const changeLanguageInfo = language_arr => {
  return dispatch => {
    dispatch({ type: LANGUAGEINFO, payload: language_arr });
  };
};

export default {
  changeLanguageInfo,
};
